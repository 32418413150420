import React from "react";
import { termsSections } from "../../utils/mock";

const TermsCondition = () => {

  return (
    <div className="container">
      <div className="policy_text">
        <div className="row justify-content-center my-2">
          <div className="col-lg-8 col-md-10 text-center">
            <div className="commantext">
              <h2 className="mb-4">Terms and Conditions</h2>
              <p className="lead mb-4">
                Welcome to Crickstarz11! By accessing or using our website and
                services, you agree to comply with and be bound by the
                following terms and conditions. Please read them carefully.
              </p>
            </div>
          </div>
        </div>

        {termsSections.map((section, index) => (
          <div className="commanOther pre" key={index}>
            <h3 className="mb-3">{section.title}</h3>
            <p style={{ whiteSpace: "pre-wrap" }}>{section.content}</p>
          </div>
        ))}

      {/*   <div className="commanOther">
          <h3 className="mb-3">14. Contact Us</h3>
          <p>
            If you have any questions or concerns about these terms and
            conditions, please contact us at:{" "}
            <a href="mailto:support@frolicfuns.com">support@frolicfuns.com</a>
          </p>
        </div>

        <p className="mt-4">
          <b>Thank you for using FrolicFuns!</b>
        </p> */}
      </div>
    </div>
  );
};

export default TermsCondition;
