import React, { useEffect, useState } from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { IoMdMailOpen } from "react-icons/io";

const Footer = () => {
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("  ");
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    console.log(link, "ooooo");
    navigate("/");
    setTimeout(() => {
      setActiveSection(link);
      scrollToSection(link);
      closeNavbar();
    }, 0);
  };

  const scrollToSection = (div) => {
    const section = document.getElementById(div);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setActiveSection("home");
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 180 &&
          scrollPosition < sectionTop + sectionHeight - 50
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      console.log(window.location.hash);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  return (
    <>
      <footer className="position-relative ">
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <a
                    onClick={() => {
                      closeNavbar();
                      handleLinkClick("home");
                    }}
                  >
                    {/* Logo */}
                    <Icon name="Logo" className="img-fluid mb-3" />
                  </a>
                  <p>
                    Owned and operated by VARINA IT SERVICES PRIVATE LIMITED.,
                    Crickstarz11.com is an online fantasy game designed for the
                    fans who like to showcase their cricket knowledge and
                    analytical skills.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Quick Links</h6>
                  <p>
                    <a
                      onClick={() => {
                        closeNavbar();
                        handleLinkClick("home");
                      }}
                    >
                      Home
                    </a>
                  </p>
                  <p>
                    <a
                      onClick={() => {
                        closeNavbar();
                        handleLinkClick("usp");
                      }}
                    >
                      Features
                    </a>
                  </p>
                  <p>
                    <a
                      onClick={() => {
                        closeNavbar();
                        handleLinkClick("whychoose");
                      }}
                    >
                      Why Choose Us?
                    </a>
                  </p>
                  <p>
                    <NavLink to={"/about-us"}>About us</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/contact-us"}>Contact Us</NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Services</h6>
                  <p>
                    <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/terms-condition"}>Terms & Condition</NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <h6>Contact Info</h6>
                  <p>
                    <a href="mailto:support@crickstarz11.in">
                      support@crickstarz11.in
                    </a>
                  </p>
                  <div className="d-flex align-items-center gap-2 followus ">
                    <a href="">
                      <FaFacebook fill="var(--primary-200)" size={24} />
                    </a>
                    <a href="">
                      <FaTwitter fill="var(--primary-200)" size={24} />
                    </a>
                    <a href="">
                      <FaLinkedin fill="var(--primary-200)" size={24} />
                    </a>
                    <a href="">
                      <IoMdMailOpen fill="var(--primary-200)" size={24} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container copy-right">
          <div className="">
            <p>iGaming @2024</p>
          </div>
        </div> */}
      </footer>
      <div className="copy-rightMain">
        <div className="container copy-right">
          <p>Copyright © 2024 Crickstarz11</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
