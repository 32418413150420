import React from "react";
import Button from "../../ui/elements/Button";
import { NavLink } from "react-router-dom";
import { Icon } from "../../ui";

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <div className="policy_text">
          <div className="row justify-content-center my-2 pt-lg-0 pt-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>About Us</h4>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-7 order-lg-1 order-2">
              <div className="commanOther">
                <h4>Our Contests</h4>
                <p>
                  You can create not one but as many as six teams for a single
                  match. You can dish out various team combinations, employ
                  captains, predict the best player of the match, pick your
                  favourite bowling pair, and whatnot.
                </p>
                <p>
                  Not only you can join contests and challenge others’ skills
                  but also create your own contest and invite your friends and
                  family.
                </p>
              </div>
              <div className="commanOther">
                <h4>Cash contests</h4>
                <p>
                  There are three types of contests that help you win cash
                  prizes: Small, Mega and Private.
                </p>
                <p>
                  The Small and Mega contests have a fixed entry fee designed by
                  our management team. Although you can create teams for free,
                  you’ll have to pay an entry fee when you join a contest. For
                  example, you have to pay three entry fees if you create three
                  teams for a contest.
                </p>
                <p>
                  On the other hand, it is you who decides the entry fees and
                  cash prizes for the Private contests. You can invite your
                  friends and family to compete.
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-lg-2 order-1 text-lg-end text-center">
              <Icon name="aboutuspage" className="img-fluid" />
            </div>
          </div>

          <div className="commanOther">
            <h4>Practice contests</h4>
            <p>
              If you are new to the world of fantasy games and want to explore
              it, you may play the Practice contests. Here, you do not have to
              pay an entry fee. Play for free and see your ranking amongst the
              best of the Fantasy players.
            </p>
          </div>
          <div className="commanOther">
            <h4>Legal Position</h4>
            <p>
              <b>Is playing Crickstarz11.com legal?</b>
              Yes, it is absolutely safe! Crickstarz11.com is a game of “skill”.
              The relevant law in India is absolutely clear that, unlike games
              of "chance", games which substantially require a player to apply
              his skill are absolutely legal.
            </p>
            <p>
              All games hosted by Crickstarz11.com are carefully designed and the
              jurisdiction of prize winners is strictly monitored to ensure
              legal compliance with relevant laws currently in force in India.
            </p>
            <p>
              If you are accessing this site from Assam, Sikkim, Nagaland,
              Odisha, Telangana, and Andhra Pradesh, we hope you will enjoy our
              practice contests. However, the law in these six states is unclear
              on playing games of skill for cash prizes. You are therefore
              prohibited from playing cash contests on Crickstarz11.com from any
              of these six states.
            </p>
            <p>
              For additional information, please{" "}
              <NavLink to={"/contact-us"}>contact us.</NavLink>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
